<template>
    <el-drawer title="演出预览" 
        :visible.sync="drawerShow" 
        :wrapperClosable="false" 
        :destroy-on-close="true" 
        append-to-body
        size="auto" 
        @close="close"
    >
        <div class="drawer-content" v-loading="dataLoading">
			<div>
				<div>预览链接：</div>
				<el-link type="primary" :href="`${sswap}/pages/activity/detail/detail?activityId=${formData.activityId}`" target="_blank" class="MT5">
					{{wapUrl}} 
				</el-link>
				<div class="MT5">
					<el-button size="mini" type="success" plain @click="copy()">复制链接</el-button>
				</div>
			</div>
			<el-form :model="formData" ref="formData" class="MT10">
                <el-form-item prop="previewPwd" label="预览密码：">
                    <el-input type="password" size="medium" v-model="formData.previewPwd" placeholder="请输入预览密码"  maxlength="20" show-word-limit show-password></el-input>
					<small class="MT5"><span class="color-danger">在审核中的演出，支持设置密码后进行预览。<br>如果不设置密码，则不能预览</span></small>
                </el-form-item>
            </el-form>
        </div>
        <div class="drawer-footer">
            <el-button @click="close">取 消</el-button>
            <el-button type="primary" :loading="btnLoading" @click="submitForm()">确 认</el-button>
        </div>
		<input class="copyTool" ref="copyTool" :value="wapUrl" />
    </el-drawer>
</template>
<script>
const defaultFormData = {
    activityId:'',
    previewPwd:''
}
export default {
	components:{
	  
	},
    data(){		
        return{
            drawerShow: false,
            formData:{
                ...defaultFormData
            },
            btnLoading: false,
            dataLoading: false,
			copyTimer: null,			
			sswap:`${process.env.VUE_APP_SSWAP_URL}`,
			wapUrl:'',
			
        }
    },
    methods:{
        show(id){
            this.formData.activityId = id
            this.getDetails();
            this.drawerShow = true
			this.wapUrl=this.sswap+'/pages/activity/detail/detail?activityId='+id
		
        },
        close(){
            this.formData = { ...defaultFormData };
            this.$refs.formData && this.$refs.formData.resetFields();
            this.drawerShow = false
        },
        getDetails(){
            this.dataLoading = true
            this.$request({
                url: '/activity/previewPwdInfo',
                params: {activityId: this.formData.activityId},
                method: "post",
            })
                .then((res) => {
                    const { state, msg, result } = res.data;
                    if (state == 1) {
                        this.formData.previewPwd = result
                    } else {
                        this.$message.error(msg || "获取数据失败,请重试");
                    }
                })
                .catch(() => {
                    this.$message.error("获取数据失败,请重试");
                })
                .finally(() => {
                    this.dataLoading = false;
                });
        },
		//复制链接
		copy(){
			
		    clearTimeout(this.copyTimer);
		    this.copyTimer = setTimeout(() => {
		        const e = this.$refs.copyTool;
		        e.select();
		        document.execCommand("Copy"); // 执行浏览器复制命令
		        this.$message.success("链接已复制，可粘贴");
		    },300)
		},
        submitForm(){
            this.$refs["formData"].validate((valid) => {
                if (valid) {
                    this.btnLoading = true;

                    this.$request({
                        url: '/activity/previewPwdSave',
                        params: {...this.formData},
                        method: "post",
                    })
                        .then((res) => {
                            const { state, msg } = res.data;
                            if (state == 1) {
                                this.$message.success("设置成功！");
                                this.close();
                            } else {
                                this.$message.error(msg || "操作失败,请重试");
                            }
                        })
                        .catch(() => {
                            this.$message.error("操作失败,请重试");
                        })
                        .finally(() => {
                            this.btnLoading = false;
                        });
                }else{
                    return false;
                }
            })
        }
    }
    
}
</script>
<style lang="scss" scoped>

.copyTool{
    opacity: 0;
    filter: alpha(opacity=0);
    // height: 1px;
}
</style>