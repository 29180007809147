<template>
     <el-dialog
        title="推广二维码"
        :visible.sync="dialogVisible"
        center
        @close="close">
        <div v-if="codeData" class="dialog-content">
            <h2 class="name">{{codeData.title}}</h2>
            <div class="tips color-info">使用二维码或者链接，推广你的演出</div>
            <el-row :gutter="40">
                <el-col :sm="codeData.tourQrCodeUrl ? 12 : 24">
                    <div class="code">
                        <vue-qr :logoSrc="logo" :text="codeData.qrCodeUrl" :size="800" :logoMargin="8" :callback="getImage1" logoBackgroundColor="#ffffff" :margin="0"></vue-qr>
                    </div>
                    演出二维码
                    <div class="btns MT10">
                        <el-button type="primary" size="medium" @click="copy(codeData.qrCodeUrl)">复制链接</el-button>
                        <el-button type="primary" size="medium" @click="downloadCode(codeImage1,codeData.name)">下载二维码</el-button>
                    </div>
                </el-col>
                <el-col v-if="codeData.tourQrCodeUrl" :sm="12">
                    <div class="code">
                        <vue-qr :logoSrc="logo" :text="codeData.tourQrCodeUrl" :size="800" :logoMargin="8" :callback="getImage2" logoBackgroundColor="#ffffff" :margin="0"></vue-qr>
                    </div>
                    巡演二维码
                    <div class="btns MT10">
                        <el-button type="primary" size="medium" @click="copy(codeData.tourQrCodeUrl)">复制链接</el-button>
                        <el-button type="primary" size="medium" @click="downloadCode(codeImage2,codeData.tourName)">下载二维码</el-button>
                    </div>
                </el-col>
            </el-row>
            <div class="MT20">
                <div class="url">演出链接: {{codeData.qrCodeUrl}}</div>
                <div v-if="codeData.tourQrCodeUrl" class="url">巡演链接: {{codeData.tourQrCodeUrl}}</div>
                <input class="copyTool" ref="copyTool" :value="qrCodeUrl" />
            </div>
        </div>
        <!-- <span slot="footer" class="dialog-footer">
            <el-button type="primary" size="medium" @click="copy">复制链接</el-button>
            <el-button type="primary" size="medium" @click="downloadCode">下载二维码</el-button>
        </span> -->
    </el-dialog>
</template>

<script>
import logo from '@/assets/img/logo2x.png';
import VueQr from 'vue-qr'

export default {
    components:{
        VueQr
    },
    data(){
        return{
            dialogVisible: false,
            codeData:null,
            logo:logo,
            codeImage1:'',
            codeImage2:'',
            copyTimer: null,
            qrCodeUrl:''
        }
    },
    methods:{
        async show(obj){
            this.codeData = {...obj};
            this.dialogVisible = true;
        },
        close(){
            this.dialogVisible = false
        },
        getImage1(url){
            this.codeImage1 = url;
        },
        getImage2(url){
            this.codeImage2 = url;
        },
        downloadCode(code,name){
            const _data = this.$utils.base64ToBlob(code);
            this.$utils.exportFile(_data,`${name}.png`);
        },
        //复制链接
        copy(url){
            this.qrCodeUrl = url;
            clearTimeout(this.copyTimer);
            this.copyTimer = setTimeout(() => {
                const e = this.$refs.copyTool;
                e.select();
                document.execCommand("Copy"); // 执行浏览器复制命令
                this.$message.success("已复制成功，可粘贴");
            },300)
        },
    }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog{
    max-width: 640px;
    min-width: 350px;
}
.dialog-content{
    text-align: center;
    line-height: 24px;
    .name{
        font-size: 14px;
        line-height: 20px;
    }
    .code{
        width: 200px;
        height: 200px;
        margin: 15px auto;
        img{
            width: 100%;
        }
    }
    .url{
        text-align: left;
    }
}
.copyTool{
    opacity: 0;
    filter: alpha(opacity=0);
    // height: 1px;
}
</style>