<template>
    <div>
        <div class="page-top-box">
            <div class="page-title-box">{{$route.meta.title}}</div>
            <div class="page-btns-box">
                <el-button v-if="$utils.have('/activity/publishBaseInfo')" type="primary" size="small" @click="add">发布演出</el-button>
                <el-button v-if="$utils.have('/activity/copyActivity')" type="primary" size="small" @click="copyEvent">复制演出</el-button>
                <el-button v-if="$utils.have('/tour/createTour')" type="primary" size="small" @click="bindTourEvent">创建巡演</el-button>
            </div>
        </div>
        <div class="page-content-box">
            <div class="page-filter-box">
                <el-row :gutter="16">
                    <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="4">
                        <el-input v-model="params.searchValue" class="input-with-select" size="small">
                            <el-select v-model="params.searchType" slot="prepend" placeholder="请选择">
                                <el-option label="演出ID" :value="1"></el-option>
                                <el-option label="演出名称" :value="2"></el-option>
                                <el-option label="巡演ID" :value="3"></el-option>
                            </el-select>
                        </el-input>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="4">
                        <el-select v-model="params.sellType" placeholder="请选择状态" size="small" @change="changeSellType">
                            <el-option :value="0" label="不限制演出状态"></el-option>
                            <el-option :value="1" label="仅看待审核的"></el-option>
                            <el-option :value="2" label="仅看销售中的"></el-option>
                            <el-option :value="3" label="仅看已结束的"></el-option>
                            <el-option :value="4" label="仅看已取消的"></el-option>
                            <el-option :value="5" label="仅看未通过的"></el-option>
                        </el-select>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="4">
                        <el-select v-model="params.progressType" placeholder="请选择报批进度" size="small">
                            <el-option :value="0" label="不限报批进度"></el-option>
                            <el-option :value="1" label="仅看已有批文的"></el-option>
                            <el-option :value="2" label="仅看报批申请中的"></el-option>
                            <el-option :value="3" label="仅看还未申请报批的"></el-option>
                        </el-select>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="4">
                        <el-select v-model="params.priorityType" placeholder="请选择" size="small">
                            <el-option :value="1" label="优先展示最近开演的"></el-option>
                            <el-option :value="3" label="优先展示最晚结束的"></el-option>
                            <el-option :value="2" label="优先展示最近通过审核的"></el-option>
                        </el-select>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="4">
                        <el-input v-model="params.cityValue" class="input-with-select" size="small">
                            <el-select v-model="params.cityType" slot="prepend" placeholder="请选择">
                                <el-option label="城市" :value="1"></el-option>
                                <el-option label="省份" :value="2"></el-option>
                            </el-select>
                        </el-input>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="4" class="page-filter-btns">
                        <el-button type="primary" @click="search" size="small">搜索</el-button>
                        <el-button @click="reset" size="small">重置</el-button>
                    </el-col>
                </el-row>
            </div>

            <div class="page-list-box">
                <el-table
                    :data="dataList"
                    style="width: 100%"
                    v-loading="listLoading"
                    size="small"
                    header-row-class-name="page-list-table"
                    @selection-change="handleSelectionChange"
                >
                    <el-table-column type="selection"></el-table-column>
                    <el-table-column prop="status" label="演出状态">
                        <template slot-scope="scope">
                            <span v-if="scope.row.status == 1" class="color-warning">待审核</span>
                            <span v-if="scope.row.status == 2" class="color-success" :class="{'color-danger':scope.row.editRemark != undefined}">
                                销售中
                                <span v-if="scope.row.editRemark" class="el-icon-warning waring" @click="showRemark(scope.row)"></span>
                            </span>
                            <span v-if="scope.row.status == 3">已结束</span>
                            <span v-if="scope.row.status == 4" class="color-info">已取消</span>
                            <span v-if="scope.row.status == 5" class="color-danger">
                                未通过
                                <span v-if="scope.row.remark" class="el-icon-warning waring" @click="showRemark(scope.row)"></span>
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="id" label="演出ID"></el-table-column>
                    <el-table-column prop="name" label="演出名称" min-width="300" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="cityName" label="城市"></el-table-column>
                    <el-table-column prop="siteName" label="场地" min-width="150" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="progress" label="报批进度" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <span v-if="scope.row.progress == 0">未知</span>
                            <span v-if="scope.row.progress == 1" class="color-success">已有批文</span>
                            <span v-if="scope.row.progress == 2" class="color-warning">申请中</span>
                            <span v-if="scope.row.progress == 3">未申请</span>
                        </template>
                    </el-table-column>
					<el-table-column  label="销售" min-width="150">
						<template slot-scope="scope">
							<p>销售票数：{{scope.row.showSaleData ? scope.row.ticketCount || 0 : '***'}}</p>
							<p>销售总额：{{scope.row.showSaleData ? scope.row.sellAmountStr || 0 : '***'}}</p>
						</template>
					</el-table-column>
					<el-table-column  label="实际收入" min-width="150">
						<template slot-scope="scope">
							<p>实际收入：{{scope.row.showSaleData ? scope.row.incomeStr || 0 : '***'}}</p>
							<p>承担优惠：{{scope.row.showSaleData ? scope.row.discountAmountStr || 0 : '***'}}</p>
						</template>
					</el-table-column>
					<el-table-column  label="退票" min-width="150">
						<template slot-scope="scope">
							<p>退票总数：{{scope.row.showSaleData ? scope.row.refundNum || 0 : '***'}}</p>
							<p>退票手续费：{{scope.row.showSaleData ? scope.row.refundFeeStr || 0 : '***'}}</p>
						</template>
					</el-table-column>
					
                    <!-- <el-table-column prop="ticketCount" label="销售总票数">
                        <template slot-scope="scope">
                            {{scope.row.showSaleData ? scope.row.ticketCount || 0 : '***'}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="sellAmountStr" label="销售总额" min-width="90">
                        <template slot-scope="scope">
                            {{scope.row.showSaleData ? scope.row.sellAmountStr || 0 : '***'}}
                        </template>
                    </el-table-column> -->
                    <!-- <el-table-column prop="refundNum" label="退票总数" min-width="90">
                        <template slot-scope="scope">
                            {{scope.row.showSaleData ? scope.row.refundNum || 0 : '***'}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="refundFeeStr" label="退票手续费" min-width="90">
                        <template slot-scope="scope">
                            {{scope.row.showSaleData ? scope.row.refundFeeStr || 0 : '***'}}
                        </template>
                    </el-table-column> -->

                    <el-table-column prop="beginTime" label="首场开演时间" min-width="150"></el-table-column>
                    <el-table-column prop="applyPassTime" label="审核通过时间" min-width="150"></el-table-column>
                    <el-table-column fixed="right" label="操作" width="60">
                        <template slot-scope="scope">
                            <el-dropdown trigger="click">
                                <el-button type="text" size="small">管理</el-button>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item  v-if="scope.row.showButton && $utils.have('/activity/detail')">
                                        <el-button
                                            type="text"
                                            size="small"
                                            @click="edit(scope.row)"
                                            >演出编辑</el-button
                                        >
                                    </el-dropdown-item>
                                    <el-dropdown-item v-if="scope.row.showStatistics && $utils.have('/activitySaleStatistice/statistics')">
                                        <el-button
                                            type="text"
                                            size="small"
                                            @click="$router.push(`/manage/activity/edit/statistics/${scope.row.id}`)"
                                            >售票统计</el-button
                                        >
                                    </el-dropdown-item>
                                    <el-dropdown-item v-if="scope.row.status == 1 || scope.row.status == 2">
                                        <el-button
                                            type="text"
                                            size="small"
                                            @click="showCode(scope.row)"
                                            >演出链接/二维码</el-button
                                        >
                                    </el-dropdown-item>
                                    <template v-if="scope.row.showCancelButton">
                                        <el-dropdown-item v-if="!scope.row.cancelStatistics && $utils.have('/activity/cancelApply')">
                                            <el-button
                                                type="text"
                                                size="small"
                                                @click="$refs.cancelActivity.show(scope.row.id)"
                                                >取消订单演出</el-button
                                            >
                                        </el-dropdown-item>
                                        <el-dropdown-item v-if="scope.row.cancelStatistics && $utils.have('/activity/cancelProgress')">
                                            <el-button
                                                type="text"
                                                size="small"
                                                @click="$refs.cancelProgressActivity.show(scope.row.applyId,scope.row.id)"
                                                >取消进度</el-button
                                            >
                                        </el-dropdown-item>
                                    </template>
                                   <el-dropdown-item v-if="(scope.row.buttonStatus == 1 || scope.row.buttonStatus == 3 ) && $utils.have('/activity/site/list')">
                                        <el-button
                                            type="text"
                                            size="small"
                                            @click="$router.push(`/manage/activity/edit/num/${scope.row.id}`)"
                                            >排队序号</el-button
                                        >
                                    </el-dropdown-item>
                                    <el-dropdown-item v-if="(scope.row.buttonStatus == 2 || scope.row.buttonStatus == 3 ) &&  $utils.have('/activity/site/list')">
                                        <el-button
                                            type="text"
                                            size="small"
                                            @click="$router.push(`/manage/activity/edit/seat/${scope.row.id}`)"
                                            >演出座位号</el-button
                                        >
                                    </el-dropdown-item>
                                    <el-dropdown-item v-if="scope.row.status ==1 ">
                                        <el-button
                                            type="text"
                                            size="small"
                                            @click="$refs.WapLink.show(scope.row.id)"
                                            >演出预览</el-button
                                        >
                                    </el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <div v-if="data" class="page-pagination-box">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="getDataList"
                    :current-page.sync="params.pageNo"
                    :page-sizes="[15, 30, 50]"
                    :page-size.sync="params.pageSize"
                    layout="sizes, prev, pager, next"
                    :total="data.totalCount || 0">
                </el-pagination>
            </div>

        </div>

        <activity-code ref="activityCode"></activity-code>
        <bind-tour :activityIds="activityIds" ref="bindTour" @onUpdate="getDataList"></bind-tour>
        <copy-activity ref="copyActivity"></copy-activity>
        <cancel-activity ref="cancelActivity" @onUpdate="getDataList"></cancel-activity>
        <cancel-progress-activity ref="cancelProgressActivity" @onUpdate="getDataList"></cancel-progress-activity>
        <wap-link ref="WapLink"></wap-link>
    </div>
</template>

<script>
import ActivityCode from "./components/activity-code";
import BindTour from './components/bind-tour';
import CopyActivity from './components/copy-activity';
import CancelActivity from './components/cancel-activity';
import CancelProgressActivity from './components/CancelProgress-activity';
import WapLink from "./components/wap-link.vue";
const defaultParams = {
    pageNo: 1,
    searchType: 1,
    searchValue: "",
    sellType: 2,
    progressType: 0,
    priorityType: 1,
    cityValue: "",
    cityType: 1
}
export default {
    components:{ActivityCode,BindTour,CopyActivity,CancelActivity,CancelProgressActivity,WapLink},
    data(){
        return{
            data: null,
            dataList: null,
            params:{
                ...defaultParams,
                pageNo:parseInt(this.$route.query.pageNo) || 1,
                pageSize: parseInt(this.$route.query.pageSize) || 15,
                searchType:parseInt(this.$route.query.searchType || 1),
                searchValue:this.$route.query.searchValue || '',
                sellType:parseInt(this.$route.query.sellType || 2),
                progressType: parseInt(this.$route.query.progressType || 0),
                priorityType:parseInt(this.$route.query.priorityType || 1),
                cityValue:this.$route.query.cityValue || '',
                cityType:parseInt(this.$route.query.cityType || 1),
            },
            listLoading: true,
            activityIds: [], //选中演出ID集合
        }
    },
    created(){
        this.getDataList();
    },
    methods:{
        //获取列表
        getDataList() {
            this.listLoading = true;
            this.$request({
                url: "/activity/list",
                method: "POST",
                data: this.params,
            })
                .then((res) => {
                    const { state, msg, result } = res.data;
                    if (state == 1 && result) {
                        this.data = result;
                        this.dataList = result.result;
                    } else {
                        this.$message.error(msg || "请求失败");
                    }
                })
                .catch(() => {
                    this.$message.error("请求错误");
                })
                .finally(() => {
                    this.listLoading = false;
                });
        },
        //选择pageSize
        handleSizeChange(e) {
            this.params.pageNo = 1;
            this.params.pageSize = e;
            this.getDataList();
        },
        //搜索
        search() {
            this.params.pageNo = 1;
            this.getDataList();
        },
        //选择销售状态
        changeSellType(e){
            if(e === 3){
                this.params.priorityType = 3;
            }
        },
        //重置搜索
        reset() {
            this.params = {
                ...defaultParams,
                pageSize: this.params.pageSize,
            };
            this.getDataList();
        },
        //新增事件
        add() {
            this.$router.push({ 
                path: "/manage/activity/add/step1",
                query: {
                    redirect: this.$utils.objArgs(
                        this.$router.currentRoute.path,
                        this.params
                    ),
                },
            });
        },
        //查看二维码
        showCode(obj){
            this.$refs.activityCode.show(obj);
        },
        //编辑事件
        edit(data){
            if(data.status != 5){
                this.$router.push({
                    path: `/manage/activity/edit/${data.id}`,
                    query: {
                        redirect: this.$utils.objArgs(
                            this.$router.currentRoute.path,
                            this.params
                        ),
                    },
                });
            }else{
                this.$router.push({ 
                    path: `/manage/activity/edit/step1/${data.id}`,
                    query: {
                        redirect: this.$utils.objArgs(
                            this.$router.currentRoute.path,
                            this.params
                        ),
                    },
                });
            }
        },
        //全选、反选
        handleSelectionChange(val) {
            this.activityIds = val.map( v => v.id );
        },
        //创建巡演事件
        bindTourEvent(){
            if( this.activityIds.length < 1 ){
                return this.$message.warning('请选择需要绑定巡演的演出');
            }else{

                this.$request({
                    url: "/tour/checkTourActivity",
                    method: "POST",
                    params: {
                        activityIds: this.activityIds
                    },
                })
                    .then((res) => {
                        const { state, msg } = res.data;
                        if (state == 1) {
                            this.$refs.bindTour.show()
                        } else {
                            this.$message.error(msg || "请求失败");
                        }
                    })
                    .catch(() => {
                        this.$message.error("请求错误");
                    })
            }
        },
        //复制演出
        copyEvent(){
            this.$refs.copyActivity.show()
        },
        //展示备注
        showRemark(target){
            const title = target.status == 5 ? '演出上线审核未通过' : "演出修改审核未通过"
            this.$alert(target.remark || target.editRemark, title, {
                confirmButtonText: '确定',
                showCancelButton:false,
                showClose:false,
                dangerouslyUseHTMLString:true,
                customClass:'mpop',
            }).then( () => {
                
            }).catch(() => {});
        }
    }
}
</script>


<style lang="scss" scoped>
.waring{
    cursor: pointer;
}
</style>