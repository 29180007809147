<template>
<div>
    <el-drawer
        title="取消进度"
        :visible.sync="drawerShow"
        :wrapperClosable="false"
        :destroy-on-close="true"
        append-to-body
        size="auto"
    >
        <div class="drawer-content" v-loading="dataLoading">
            <table v-if="data" class="info-table" style="width:100%">
                <tr>
                    <th>申请取消时间</th>
                    <td>{{data.applyTime}}</td>
                </tr>
                <tr>
                    <th>取消范围</th>
                    <td><div v-for="(item,index) in data.cancelRange" :key="index">{{item}}</div></td>
                </tr>
                <tr>
                    <th>取消原因</th>
                    <td>{{data.cancelReason}}</td>
                </tr>
                <tr>
                    <th>取消函</th>
                    <td><el-button v-if="data.cancelLetterUrl" type="text" @click="showViewer=true" size="mini">点击查看</el-button></td>
                </tr>
                <tr>
                    <th>取消进度</th>
                    <td :class="status[data.status].class">
                        {{status[data.status].text}}
                        <template v-if="data.status == 3">(原因：{{data.remark}})</template>
                    </td>
                </tr>
            </table>
        </div>
        <div v-if="data" class="drawer-footer">
            <el-button v-if="$utils.have('/activity/revokeCancelApply') && (data.status == 0 || data.status == 1)" type="danger" @click="revokeCancelApply" v-loading="btnLoading">撤销申请</el-button>
            <el-button v-if="$utils.have('/activity/cancelApply') && (data.status == 3 || data.status == 4)" type="primary" @click="apply">重新发起取消</el-button>
            <el-button v-if="$utils.have('/activity/cancelApply') && data.canRelaunch" type="primary" @click="apply">发起新的取消</el-button>
        </div>
    </el-drawer>
    <el-image-viewer v-if="showViewer && data.cancelLetterUrl" :on-close="closeViewer" :url-list="[data.cancelLetterUrl]" :z-index="100000"/>
</div>
</template>
<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
export default {
    components:{ElImageViewer},
    data(){
        return{
            drawerShow: false,
            dataLoading: false,
            applyId: "",
            activityId: "",
            data: null,
            showViewer: false,
            btnLoading: false
        }
    },
    computed:{
        status(){
            return {
                0: {text: "待初审", class: "color-warning"},
                1: {text: "待复审", class: "color-warning"},
                2: {text: "已通过", class: "color-success"},
                3: {text: "已拒绝", class: "color-danger"},
                4: {text: "已撤销", class: "color-info"},
            }
        }
    },
    methods:{
        show(id,activityId){
            this.applyId = id;
            this.activityId = activityId;
            this.cancelProgress();
            this.drawerShow = true;
        },
        //获取进度数据
        cancelProgress(){
            this.dataLoading = true;
            this.$request({
                url: "/activity/cancelProgress",
                params: { applyId: this.applyId },
                method: "post",
            })
                .then((res) => {
                    const { state, msg, result } = res.data;
                    if (state == 1) {
                        this.data = result;
                    } else {
                        this.$message.error(msg || "获取取消进度失败,请重试");
                    }
                })
                .catch(() => {
                    this.$message.error("获取取消进度失败,请重试");
                })
                .finally(() => {
                    this.dataLoading = false;
                });
        },
        closeViewer(){
            this.showViewer = false
        },
        //撤销申请
        revokeCancelApply(){
            this.$confirm('是否确定撤销申请？', '提示', {
                center: true,
                closeOnClickModal: false
            }).then(() => {
                this.btnLoading = true;
                this.$request({
                    url: "/activity/revokeCancelApply",
                    params: { applyId: this.applyId },
                    method: "post",
                })
                    .then((res) => {
                        const { state, msg } = res.data;
                        if (state == 1) {
                            this.drawerShow = false;
                            this.$emit('onUpdate');
                        } else {
                            this.$message.error(msg || "操作失败,请重试");
                        }
                    })
                    .catch(() => {
                        this.$message.error("操作失败,请重试");
                    })
                    .finally(() => {
                        this.btnLoading = false;
                    });
            }).catch(() => {
                
            })
        },
        // 申请取消
        apply(){
            this.drawerShow = false;
            this.$parent.$refs.cancelActivity.show(this.activityId);
        }
    }
}
</script>

<style lang="scss" scoped>

</style>