<template>
    <el-drawer
        title="创建巡演"
        :visible.sync="drawerShow"
        :wrapperClosable="false"
        :destroy-on-close="true"
        append-to-body
        size="auto"
        @close="close"
    >
        <div class="drawer-content">
            <el-form v-if="isBindList.length < 1" :model="formData" ref="formData">
                <el-form-item prop="tourName" label="巡演名称:" :rules="[{required: true, message: '请选择巡演或输入'}]">
                    <el-autocomplete
                        v-model="formData.tourName"
                        :fetch-suggestions="searchTour"
                        placeholder="请输入巡演名称"
                        @select="selectTour"
                        :trigger-on-focus="false"
                        size="medium"
                        style="width:100%"
                        ref="searchInput"
                        >
                        <template slot-scope="{ item }">
                            <template v-if="noData">
                                没有找到相关数据
                            </template>
                            <div class="name" v-else>{{ item.name }}</div>
                        </template>
                    </el-autocomplete>
                </el-form-item>
            </el-form>
            <div v-else>
                <div>当前尝试绑定的巡演：{{formData.tourName}}</div>
                <div class="color-danger MT20">部分演出已经绑定有其他巡演，具体如下：如果【确认绑定】，会自动解除与这些巡演的绑定关系</div>
                <el-table
                    :data="isBindList"
                    style="width: 100%"
                    size="small"
                    stripe
                    class="MT20"
                >
                    <el-table-column prop="id" label="演出ID"></el-table-column>
                    <el-table-column prop="name" label="巡演" min-width="300"></el-table-column>
                </el-table>
            </div>
        </div>
        <div class="drawer-footer">
            <el-button @click="close">取 消</el-button>
            <el-button v-if="isBindList.length < 1" type="primary" :loading="btnLoading" @click="parseTour">确 定</el-button>
            <el-button v-else type="primary" :loading="btnLoading" @click="bindEvent">确认绑定</el-button>
        </div>
    </el-drawer>
</template>

<script>
const defaultFormData = {
    tourId:'',
    tourName:'',
    // reBind: ""
}
export default {
    props:{
        activityIds:{
            type:Array,
            default: () => []
        }
    },
    data(){
        return{
            drawerShow: false,
            formData:{
                ...defaultFormData
            },
            tourList:[],
            searchLoading:false,
            btnLoading:false,
            tourItem:null,
            isBindList:[],
            noData: false,
            word:''
        }
    },
    methods:{
        show(){
            this.drawerShow = true
        },
        close(){
            this.formData = { ...defaultFormData };
            this.$refs.formData && this.$refs.formData.resetFields();
            this.isBindList = [];
            this.tourItem = null;
            this.drawerShow = false
        },
        //搜索巡演
        searchTour(query,cb){
            if(query != ""){
                this.word = query;
                this.searchLoading = true;
                this.noData = false;
                this.$request({
                    url: '/tour/searchByKeyWord',
                    method: 'POST',
                    params: {keyword:query}
                }).then(res => {
                    const { state, result } = res.data;
                    if( state == 1 ){
                        if(result && result.length > 0){
                            this.tourList = result;
                            cb(this.tourList);
                        }else{
                            this.tourList = [];
                            this.formData.tourId = '';
                            this.formData.tourName = query;
                            this.noData = true;
                            cb([this.noData]);
                        }
                    }
                }).catch( () => {
                    this.tourList = [];
                    this.formData.tourId = '';
                    this.formData.tourName = query;
                    this.noData = true;
                    cb([this.noData]);
                }).finally(() => {
                    this.searchLoading = false;
                });
            }
        },
        //选择巡演
        selectTour(e){
            if(this.noData){
                this.formData.tourName = this.word
            }else{
                this.formData.tourId = e.id;
                this.formData.tourName = e.name;
            }
        },
        //解析
        parseTour(){
            this.$refs["formData"].validate((valid) => {
                if (valid) {
                    this.btnLoading = true;

                    this.$request({
                        url: '/tour/createTour',
                        data: {...this.formData,activityIds:this.activityIds.toString()},
                        method: "post",
                    })
                        .then((res) => {
                            const { state, msg, result } = res.data;
                            if (state == 1) {
                                this.formData.reBind = result.tip || '';
                                if( result && result.clashList && result.clashList.length > 0 ){
                                   this.isBindList = result.clashList;
                                }else{
                                   this.bindEvent();
                                }
                            } else {
                                this.$message.error(msg || "操作失败,请重试");
                            }
                        })
                        .catch(() => {
                            this.$message.error("操作失败,请重试");
                        })
                        .finally(() => {
                            this.btnLoading = false;
                        });
                }else{
                    return false;
                }
            })    
        },
        //绑定巡演
        bindEvent(){
            this.btnLoading = true;

            this.$request({
                url: '/tour/createTour',
                data: {...this.formData,activityIds:this.activityIds.toString()},
                method: "post",
            })
                .then((res) => {
                    const { state, msg } = res.data;
                    if (state == 1) {
                        // this.$message.success("绑定成功");
                        // this.close();
                        // this.$emit('onUpdate');

                        this.$confirm('您可以在巡演管理页面看到这些巡演', '绑定巡演成功！', {
                            type: 'success',
                            center: true,
                            confirmButtonText:'去巡演管理列表',
                            cancelButtonText:'留在当前页面',
                            closeOnClickModal: false
                        }).then(() => {
                            this.$router.push('/manage/activity/tour');
                        }).catch(() => {
                            this.$emit('onUpdate');
                        })

                        this.close();

                    } else {
                        this.$message.error(msg || "操作失败,请重试");
                    }
                })
                .catch(() => {
                    this.$message.error("操作失败,请重试");
                })
                .finally(() => {
                    this.btnLoading = false;
                });
        }
    }
}
</script>
<style lang="scss" scoped>

</style>