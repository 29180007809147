<template>
    <el-drawer
        title="取消订单演出"
        :visible.sync="drawerShow"
        :wrapperClosable="false"
        :destroy-on-close="true"
        append-to-body
        size="auto"
        @close="close"
    >
        <div class="drawer-content" v-loading="dataLoading">
            <el-form :model="formData" ref="formData" label-position="top" size="medium">
                <el-form-item prop="type" label="选择取消逻辑:" :rules="[{required: true, message: '请选择取消逻辑'}]">
                    <el-select v-model="formData.type">
                        <el-option :value="1" label="取消所有订单（需退票），但保留演出"></el-option>
                        <el-option :value="2" label="取消所有订单（需退票），且取消演出"></el-option>
                    </el-select>
                    <div v-if="formData.type == 1" class="color-warning">通常用于演出整改延期的情况</div>
                    <div v-if="formData.type == 2" class="color-warning">通常用于演出不再举办的情况</div>
                </el-form-item>
                <el-form-item prop="sessionIds" label="选择取消场次:" :rules="[{required: true, message: '请选择需要取消的场次'}]">
                    <el-checkbox-group v-model="formData.sessionIds">
                        <el-checkbox v-for="item in sessions" :key="item.id" :label="item.id">
                            {{item.name}}
                            <small>(开演时间：{{item.startTime}})</small>
                        </el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item prop="cancelReason" label="取消原由:" :rules="[{required: true, message: '请填写取消原由'}]">
                    <el-input v-model="formData.cancelReason" placeholder="请填写取消原由" type="textarea" maxlength="200" show-word-limit :rows="4"></el-input>
                </el-form-item>
                <el-form-item prop="cancelLetterUrl" label="上传取消函图片:" :rules="[{required: true, message: '请上传取消函图片'}]">
                    <image-upload 
                        :url.sync="formData.cancelLetterUrl" 
                        :urlFull.sync="formData.cancelLetterUrl" 
                        @onSuccess="handleImageSuccess"
                    >
                        <!-- <span slot="size">建议宽高比3:4,不小于1200*1600px</span> -->
                    </image-upload>
                </el-form-item>
            </el-form>
        </div>
        <div class="drawer-footer">
            <el-button @click="close">取 消</el-button>
            <el-button type="primary" :loading="btnLoading" @click="bindEvent">提交取消申请</el-button>
        </div>
    </el-drawer>
</template>
<script>
import ImageUpload from '@/components/ImageUpload';
const defaultFormData = {
    activityId:'',
    cancelLetterUrl:'',
    cancelReason: "",
    sessionIds: [],
    type: ""
}
export default {
    components:{ImageUpload},
    data(){
        return{
            drawerShow: false,
            formData:{
                ...defaultFormData
            },
            btnLoading: false,
            dataLoading: false,
            sessions: []
        }
    },
    methods:{
        show(id){
            this.formData.activityId = id;
            this.getSessionsById();
            this.drawerShow = true
        },
        close(){
            this.formData = { ...defaultFormData };
            this.$refs.formData.resetFields();
            this.drawerShow = false
        },
        getSessionsById(){
            this.dataLoading = true;
            this.$request({
                url: "/activity/sessionInfo",
                params: { activityId: this.formData.activityId },
                method: "post",
            })
                .then((res) => {
                    const { state, msg, result } = res.data;
                    if (state == 1) {
                        this.sessions = result;
                    } else {
                        this.$message.error(msg || "获取演出场次失败,请重试");
                    }
                })
                .catch(() => {
                    this.$message.error("获取演出场次失败,请重试");
                })
                .finally(() => {
                    this.dataLoading = false;
                });
        },
        //图片上传成功后执行
        handleImageSuccess(){
            if(this.formData.cancelLetterUrl){
                this.$refs["formData"].clearValidate('cancelLetterUrl');
            }
        },
        bindEvent(){
            this.$refs["formData"].validate((valid) => {
                if (valid) {
                    this.btnLoading = true;
                    this.$request({
                        url: '/activity/cancelApply',
                        data: {...this.formData},
                        method: "post",
                    })
                        .then((res) => {
                            const { state, msg } = res.data;
                            if (state == 1) {
                                this.$emit('onUpdate');
                                this.close();

                            } else {
                                this.$message.error(msg || "操作失败,请重试");
                            }
                        })
                        .catch(() => {
                            this.$message.error("操作失败,请重试");
                        })
                        .finally(() => {
                            this.btnLoading = false;
                        });
                }
            })
        }
    },
}
</script>
<style lang="scss" scoped>

</style>